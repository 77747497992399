import React, {
  ChangeEvent,
  FC,
  MutableRefObject,
  memo,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { CreateArContext } from '@src/context';
import { ITEM_TYPE } from '@src/core/types';
import { Format, findTypeByFormat } from '@src/pages/create-ar/utils';
import FileUploader from '@ui-kit/file-uploader';

import {
  Input,
  InputWrapper,
  Root,
  StyledCheckbox,
  Title,
  TypeWrapper,
} from './form-content.styles';

interface FormContentProps {}

export const FormContent: FC<FormContentProps> = memo(() => {
  const { onDataItemChange, dataItem, setDisabledNext } =
    useContext(CreateArContext);
  const { t } = useTranslation();
  const [checked, setChecked] = useState(dataItem?.isAlpha);

  useEffect(() => {
    setDisabledNext(!dataItem?.itemFile);
  }, []);

  const handleAlphaChange = () => {
    const nextVal = !checked;
    setChecked(nextVal);
    onDataItemChange((item) => {
      return {
        ...item,
        isAlpha: nextVal,
      };
    });
  };

  const handleActionUrlChange = (e: ChangeEvent<HTMLInputElement>) => {
    onDataItemChange((item) => {
      return {
        ...item,
        actionUrl: e.target.value,
        type: ITEM_TYPE.WIDGET,
      };
    });
  };

  const handleFileUploaderChange = (
    ref: MutableRefObject<HTMLInputElement | null>
  ) => {
    const file = ref.current?.files && ref.current?.files[0];
    console.log('handleFileUploaderChange format ');

    if (file) {
      let format = file.type.split('/')?.[1];

      if (!format) {
        const splitName = file.name.split('.');
        format = splitName[splitName.length - 1];
      }
      const itemType = findTypeByFormat(format);

      if (itemType) {
        onDataItemChange((item) => {
          return {
            ...item,
            itemFile: file,
            type: itemType,
          };
        });
        setDisabledNext(!file);
      }
    }
  };

  return (
    <Root>
      <Title>{t('common.selectFile')}</Title>
      <FileUploader
        accept={Format}
        selectedFileName={dataItem?.itemFile?.name}
        onChange={handleFileUploaderChange}
        description={
          <>
            {!dataItem?.itemFile && (
              <>
                <p>
                  {t('createAr.contentForm.tip', {
                    format: Format,
                  })}
                </p>
                <p>{t('createAr.contentForm.extraTip')}</p>
              </>
            )}
            {dataItem?.type === ITEM_TYPE.VIDEO && (
              <p>{t('createAr.contentForm.tipByType.video')}</p>
            )}
            {(dataItem?.type === ITEM_TYPE.IMAGE ||
              dataItem?.type === ITEM_TYPE.WIDGET) && (
              <p>{t('createAr.contentForm.tipByType.image')}</p>
            )}
          </>
        }
      />
      {dataItem?.type === ITEM_TYPE.VIDEO && (
        <TypeWrapper>
          <StyledCheckbox
            checked={checked}
            onChange={handleAlphaChange}
            label={t('common.alphaChannel')}
            variant="light"
          />
        </TypeWrapper>
      )}
      {(dataItem?.type === ITEM_TYPE.IMAGE ||
        dataItem?.type === ITEM_TYPE.WIDGET) && (
        <TypeWrapper>
          <InputWrapper>
            <Input
              placeholder={t('createAr.addItem.trigger.widgetLabel')}
              onChange={handleActionUrlChange}
              defaultValue={dataItem.actionUrl}
            />
          </InputWrapper>
        </TypeWrapper>
      )}
    </Root>
  );
});
